import Image from "next/image";
import Link from "next/link";
import { AspectRatio } from "../ui/aspect-ratio";
import { cn } from "~/lib/utils";
import StrapiBlocksRenderer from "~/components/reusables/strapi-blocks-renderer";

const ProductMenuCard = ({
  product,
  horizontalLayout,
  forceHeight = "null",
}) => {
  const blogImage = product.img?.data?.attributes?.url;
  return (
    <div>
      <Link
        href={`${product.url}`}
        className={
          horizontalLayout ? "group flex flex-col gap-5" : "group flex gap-8"
        }
      >
        <div
          className={cn(
            "relative mx-auto aspect-[15/7] h-auto w-full flex-[.45] overflow-hidden rounded-xl border border-[#4C4C4C]",
            forceHeight,
          )}
        >
          <div className="w-full">
            <AspectRatio ratio={horizontalLayout ? 300 / 140 : 200 / 120}>
              <Image
                unoptimized
                src={blogImage}
                fill
                alt="Featured home blog"
                className="w-[90%] rounded-xl object-cover transition-all duration-700 group-hover:scale-105"
                sizes="100%"
              />
            </AspectRatio>
          </div>
        </div>
        <div className="flex-[.55]">
          {product.title && (
            <div className="mt-1 text-[#A6A6A6]">
              <h5 className="font-p-sm">{product.title}</h5>
            </div>
          )}
          {product.description && (
            <div
              className={
                horizontalLayout
                  ? "mt-1 text-[#A6A6A6]"
                  : "mt-1 text-[#A6A6A6] md:mt-0"
              }
            >
              <StrapiBlocksRenderer content={product.description} />
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default ProductMenuCard;
